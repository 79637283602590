<template>
  <div class="vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
      <vx-card class="login-card py-3 px-3" title="Sesión finalizada.">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="px-6 pt-6 login-tabs-container">
              <p v-if="isAutomaticClose">
                Por seguridad, tu sesión ha sido finalizada automáticamente debido a que <span class="bold">no se detectó actividad durante 5 minutos</span>.
                ¡Gracias por ser parte de la Red!
              </p>
              <p v-if="isTokenFinished">
                Tu sesión ha sido finalizada, ya que por seguridad <span class="bold">solicitaremos que inicies sesión al menos cada 60 minutos</span>.
                ¡Gracias por ser parte de la Red!
              </p>
              <div class="mt-base mb-6">
                <div class="text-dark">
                  ¿Deseas iniciar sesión nuevamente? <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="goToLogin">Da clic aquí.</router-link></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "SessionLogout",
  props: ["reason"],
  methods: {
    async goToLogin(){
      await this.$router.push({name: 'login'});
    },
  },
  computed: {
    isAutomaticClose(){
      return this.reason === 'timeout';
    },
    isTokenFinished(){
      return this.reason === 'lifetime';
    }
  },
}
</script>
